@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@1,700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.loader{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.sec1cont{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
}

.landtextcont{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    /* font-family: 'Rubik', sans-serif; */
    height: 100%;
    justify-content: center;
    width: 60%;

}



.bgimg{
  background-image: url('../public/landimg3.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 700px;
  background-blend-mode:lighten;
}

.blackwrap{
  background-color: rgb(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
}

.landtextt1{
  /* font-family: 'Roboto', sans-serif; */
  color: white;
  /* font-weight: 900; */
  line-height: 60px;
  font-size: 58px;
  word-spacing: 7px;
  line-height: 1.4;
  margin-bottom: 30px;
  /* font-size: 30px; */
}

.exwhatsin{
  color: white;
  font-family: 'Pacifico', cursive;
}
/* From cssbuttons.io by @NorthFishHasNa */
.button1 {
 display: inline-block;
 border-radius: 4px;
 background-color: #ff3232;
 border: none;
 color: #FFFFFF;
 text-align: center;
 font-size: 17px;
 padding: 10px;
 width: 200px;
 transition: all 0.5s;
 cursor: pointer;
 margin: 5px;
 margin-top: 40px;
}

.button1 span {
 cursor: pointer;
 display: inline-block;
 position: relative;
 transition: 0.5s;
}

.button1 span:after {
 content: '»';
 position: absolute;
 opacity: 0;
 top: 0;
 right: -15px;
 transition: 0.5s;
}

.button1:hover span {
 padding-right: 15px;
}

.button1:hover span:after {
 opacity: 1;
 right: 0;
}


.sec2title{
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  height: 300px;
  justify-content: center;
}

.sec2t1{
  font-family: 'Pacifico', cursive;
  color: #201d1d;
  font-size: 30px;
  margin-bottom: 20px;
}

.sec2title span{
  font-size: 22px;
}


.sec3cont{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sec3ti{
  /* font-family: 'Pacifico', cursive; */
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 32px;
  margin-bottom: 30px;
}

.industdiv{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100%;
  justify-items: center;
}

.indust1{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* padding: 40px; */
  border-radius: 20px;
  width: 300px;
  margin-bottom: 40px;
}

.indust1 img
{
  height: auto;
  width: 100%;
  height: 65%;
  border-radius: 20px 20px 0px 0px ;
}
.indtextpad{
  padding: 20px;
}

.sec4{
  /* height: 500px; */
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.sec4cont{
  display: flex;
  flex-direction: row;width: 100%;
  justify-content: center;
  align-items: center;
}

.sec4textcont{
  width: 50%;
  height: 100%;
  /* background-color: red; */
  padding: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.sec4textcont h1{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 32px;
  margin-bottom: 40px;
}

.sec4cont li{
  font-size: 20px;
  text-decoration: none;
  list-style: none;
}

.caro{
  margin-top: 50px;
  margin-bottom: 50px;
  width: 50%;

}


.meeteamh1{
  color: #ff0000;
  font-size: clamp(2.5rem, 10vw, 5rem);
  font-family: "Parisienne", cursive;
  margin-bottom: 30px;
}

.meetcont{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-heading h2 {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.site-heading h2 span {
  color: #ff5a6e;
}

.site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.site-heading h4::before {
  background: #ff5a6e none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

.site-heading h2 span {
  color: #ff5a6e;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.team-area .single-item {
  margin-bottom: 30px;
}

.team-area .item .thumb {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.team-area .item .thumb::after {
  background: #232323 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 100%;
}

.team-area .team-items .item:hover .thumb::after {
  opacity: 0.7;
}

.team-area .item .thumb .overlay {
  top: -100%;
  left: 0;
  padding: 20px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  width: 100%;
  z-index: 1;
}

.team-area .item:hover .thumb .overlay {
  top: 10%;
  /* transform: translate(-50%); */

}

.team-area .item .thumb .overlay p {
  color: #ffffff;
}

.team-area .item .thumb .overlay h4 {
  color: #ffffff;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}

.team-area .item .thumb img {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.team-area .item:hover .thumb img {
  opacity: 0.6;
}

.team-area .item .thumb .social li {
  display: inline-block;
}

.team-area .item .thumb .social li a {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin: 0 2px;
  text-align: center;
  width: 40px;
}

.team-area .info {
  background: #ffffff none repeat scroll 0 0;
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  padding: 40px 20px 20px;
  position: relative;
  text-align: center;
  z-index: 9;
}

.team-area .info .message {
  height: 50px;
  line-height: 40px;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 50px;
}

.team-area .info .message a {
  background: #fff none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  box-sizing: border-box;
  color: #ff5a6e;
  display: inline-block;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  width: 50px;
}

.team-area .info .message a i {
  font-weight: 500;
}

.team-area .info h4 {
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.team-area .info span {
  color: #ff5a6e;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.team-area .social li.twitter a {
  background-color: #00b6f1;
}

.team-area .social li.pinterest a {
  background-color: #bd081c;
}

.team-area .social li.facebook a {
  background-color: #3b5998;
}

.team-area .social li.google-plus a {
  background-color: #df4a32;
}

.team-area .social li.vimeo a {
  background-color: #1ab7ea;
}

.team-area .social li.instagram a {
  background-color: #cd486b;
}


@media only screen and (max-width: 991px) {
  .landtextt1{

    font-size: 42px;

  }

  .sec2title span{
    font-size: 18px;
  }

  .bgimg{
      height: 500px;
  }

  .none{
    display: none;
  }

  .sec4cont{
    flex-direction: column;
  }

  .sec4textcont{
    width: 100%;
  }

  .caro{
    width: 80%;
  }
}


@media only screen and (max-width: 593px) {

  .landtextt1{

    font-size: 30px;

  }

}

#testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.testimonial-heading {
  margin: 30px 0px;
  padding: 10px 20px;
}

.testimonial-heading span {
  font-size: 1.3rem;
  color: #252525;
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.testimonial-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.testimonial-box {
  width: 500px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  margin: 15px;
  cursor: pointer;
}
.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.profile {
  display: flex;
  align-items: center;
}
.name-user {
  display: flex;
  flex-direction: column;
}
.name-user strong {
  color: #3d3d3d;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
}
.name-user span {
  color: #979797;
  font-size: 0.8rem;
}
.reviews {
  color: #f9d71c;
}
.box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.client-comment p {
  font-size: 0.9rem;
  color: #4b4b4b;
}
.testimonial-box:hover {
  transform: translateY(-10px);
  transition: all ease 0.3s;
}

@media (max-width: 1060px) {
  .testimonial-box {
    width: 45%;
    padding: 10px;
  }
}
@media (max-width: 790px) {
  .testimonial-box {
    width: 100%;
  }
  .testimonial-heading h1 {
    font-size: 1.4rem;
  }
}
@media (max-width: 340px) {
  .box-top {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .reviews {
    margin-top: 10px;
  }
}
::selection {
  color: #ffffff;
  background-color: #252525;
}
